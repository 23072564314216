import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import router from './router'
import axios from 'axios'
// import VueAxios from 'vue-axios'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
// import VueAnimateOnScroll from 'vue-animate-onscroll'

// import cors from 'cors';

import * as VueGoogleMaps from 'vue2-google-maps'
// Vue.use(VueAnimateOnScroll)


import { 
  LMap, 
  LTileLayer,
  LMarker,
  LCircle,
  LRectangle,
  LPolygon,
  LPolyline,
  LPopup,

} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-circle', LCircle);
Vue.component('l-rectangle', LRectangle);
Vue.component('l-polygon', LPolygon);
Vue.component('l-polyline', LPolyline);
Vue.component('l-popup', LPopup);
// Vue.component('l-popup', LDrawToolbar);
// import VueResource from 'vue-resource'
////////////////////////////////////////////////  CORS ///////////////////////////////////////////
// var express = require('express')
// var cors = require('cors')
// var app = express()

// app.use(cors())

// app.get('/products/:id', function (req, res, next) {
//   res.json({msg: 'This is CORS-enabled for all origins!'})
// })

// app.listen(80, function () {
//   console.log('CORS-enabled web server listening on port 80')
// })
/////////////////////////////////////////////////////////////////////////////////////////////////
import {Chart} from 'chart.js'
import Chartkick from 'vue-chartkick'
Vue.use(Chartkick.use(Chart));

Vue.config.productionTip = false
// Vue.use(VueAxios, axios)
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api'


Vue.prototype.$http = axios;
// Vue.prototype.$api = 'http://127.0.0.1:8000/api';
Vue.prototype.$api = 'http://backendlaravel.mobsemolap.uajy.ac.id/api';
// Vue.prototype.$api = 'https://backendlaravel.mobolap.com/api';



// Vue.http.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:7200/repositories/FFB'
// Vue.http.headers.common['Access-Control-Request-Method'] = '*'
// Vue.prototype.$api = "http://lod.if.fti.uajy.ac.id:7200";
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDYs3t1HIkNrhLmr1cB1zod7BO9U5rGV4A',
    // libraries: 'places', 
  },
})

new Vue({
    vuetify,
    router,
    render: h => h(App),
}).$mount('#app')