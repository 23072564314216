import Vue from 'vue'
import VueRouter from 'vue-router'
// Vue.use(VueRouter)
function importComponent(path) {
    return () => import(`./components/${path}.vue`)
}
Vue.use(VueRouter);
const router = new VueRouter({
    // mode: "history",
    mode: "hash",
    routes: [
        {
            path: "/",
            component: importComponent('DashboardLayout'),
            children: [
                {
                    path: "/",
                    name: "Dashboard",
                    meta: {title: 'Dashboard'},
                    component: importComponent('Dashboard'),
                },
                {
                    path: "/SPARQLGen",
                    name: "SQLGenerator",
                    meta: {title: 'SPARQLGen'},
                    component: importComponent('TodoList/SPARQLGen'),
                },
            ]
        },
    ]
});
export default router;