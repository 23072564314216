<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>
<script>
// import PlanetChart from './components/PlanetChart.vue'
export default {
  name: "App",
//  components: {
//     PlanetChart
//   }
};
</script>
<style>
@font-face {
  font-family: poppinslight;
  src: url("./assets/fonts/Poppins-Light.ttf");
}
@font-face {
  font-family: poppinsreguler;
  src: url("./assets/fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: poppinsbold;
  src: url("./assets/fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: poppinssemibold;
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
}
.cardtitle{
  font-family: poppinssemibold;
  color: white;
  font-size: 35px;
}
.warningCaption{
  font-family: poppinslight;
  color: black;
  margin-top: -15px;
  font-size: 10px;
}
.endpointCaption{
  font-family: poppinslight;
  color: black;
  font-size: 10px;
}
.halamanJudul{
  font-family: poppinssemibold;
  color: black;
  margin-top: 10px;
  font-size: 45px;
}
.maplogjudul{
  font-family: poppinssemibold;
  color: aliceblue;
  margin-top: 10px;
  font-size: 25px;
}
.itemtitle{
  font-family: poppinssemibold;
  color: aliceblue;
}
</style>